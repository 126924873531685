.gradientTextNeonPink {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(.25turn, #dc03b9, #4102e1);
  -webkit-background-clip: text;
  background-clip: text;
}

html {
  font-size: 16px;
}

body {
  color: #030303;
  background: #e3e2e2;
  font-family: Open Sans, Oswald, sans-serif;
}

a {
  text-decoration: none;
}

.hidden {
  display: none !important;
}

.oswald-font {
  font-optical-sizing: auto;
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: 400;
}

.os-font {
  font-optical-sizing: auto;
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: 400;
}

@media screen and (width <= 1024px) {
  .hidden-md {
    display: none !important;
  }
}

@media screen and (width <= 768px) {
  .hidden-sm {
    display: none !important;
  }
}

@media screen and (width <= 640px) {
  .hidden-xs {
    display: none !important;
  }
}

@media screen and (width >= 1024px) {
  .visible-md {
    display: none !important;
  }
}

@media screen and (width >= 768px) {
  .visible-sm {
    display: none !important;
  }
}

@media screen and (width >= 640px) {
  .visible-xs {
    display: none !important;
  }
}

button {
  box-shadow: none;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all .13s ease-out;
}

button[disabled] {
  cursor: not-allowed;
  background: #9d9e9e;
}

@media (pointer: fine) {
  button:hover:not([disabled]) {
    -ms-transform: scale(1.02);
    transform: scale(1.02);
  }
}

.page-wrapper {
  position: relative;
  overflow: hidden;
}

.designed-path {
  z-index: 0;
  position: absolute;
  top: 100rem;
  right: 0;
}

.designed-path:first-child {
  top: 4rem;
  left: -40rem;
}

.designed-path:nth-child(2) {
  top: -20rem;
  left: -15rem;
}

.designed-path:nth-child(3) {
  top: -4rem;
  right: -20rem;
}

.designed-path:nth-child(4) {
  top: 50rem;
  left: -40rem;
}

.designed-path:nth-child(5) {
  top: 50rem;
  right: -30rem;
}

.header-wrapper {
  z-index: 2;
  margin: 2.5rem 1rem;
  position: relative;
}

.header-wrapper .header {
  background-color: gray;
  border-radius: 13px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
}

.header-wrapper .header-additional {
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
  display: flex;
}

.header-wrapper .header-additional-button {
  color: #fff;
  background: linear-gradient(#fe6301 0%, #983b01 258.33%);
  border: 1px solid #e25800;
  border-radius: 8px;
  align-items: center;
  column-gap: .5rem;
  padding: .5rem .875rem;
  font-size: 1.125rem;
  display: flex;
}

.header-wrapper .header-additional-button--tg {
  background: linear-gradient(181.07deg, #00c0ff .81%, #0072db 103.9%);
  border: 1px solid #0081ef;
}

.header-wrapper .header-contact-phone {
  color: #fff;
  font-size: 1.25rem;
}

@media screen and (width <= 768px) {
  .header-wrapper {
    margin: 1rem 1rem 2rem;
  }

  .header-wrapper .header {
    background: none;
    padding: 0;
  }

  .header-wrapper .header-logo {
    height: 2rem;
  }

  .header-wrapper .header-additional {
    column-gap: .5rem;
  }

  .header-wrapper .header-logo-gl {
    height: 2rem;
  }
}

.main-button {
  color: #fff;
  text-align: center;
  background: #fe6300;
  border-radius: 1rem;
  padding: .75rem 2rem;
  font-size: 1.75rem;
  line-height: 2rem;
  box-shadow: 0 3.81px 8.57px #0000001a;
}

.slider {
  width: 100%;
  padding: 0 0 8rem !important;
}

.slider .swiper-pagination {
  position: absolute;
  bottom: 5.5rem !important;
}

.slider .swiper-pagination-bullet-active {
  background: #fff;
}

.slider .swiper-wrapper {
  position: relative;
}

.slider .slider-arrow {
  z-index: 10;
  cursor: pointer;
  align-items: center;
  height: 100%;
  transition: all .2s;
  display: flex;
  position: absolute;
}

.slider .slider-arrow:hover {
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.slider .slider-left-arrow {
  padding-left: 2rem;
  left: calc(50% - 512px - 1rem);
}

.slider .slider-right-arrow {
  padding-right: 2rem;
  right: calc(50% - 512px - 1rem);
}

.slider .slider-slide {
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 380px;
  display: flex;
  position: relative;
}

.slider .slider-content-wrapper {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1024px;
  padding: 0 3rem;
  display: flex;
  position: relative;
}

.slider .slider-content {
  position: relative;
}

.slider .slider-title-wrapper {
  align-items: flex-start;
  display: flex;
}

.slider .slider-title-wrapper span {
  margin-left: 1.2rem;
  font-family: Oswald, serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.4rem;
}

.slider .slider-title {
  text-transform: uppercase;
  min-height: 9rem;
  font-family: Oswald, sans-serif;
  font-size: 3.75rem;
  font-weight: 400;
  line-height: 4.5rem;
}

.slider .slider-slide:nth-child(4) .slider-title {
  font-size: 3rem;
  line-height: 3.5rem;
}

.slider .slider-slide:nth-child(6) .slider-title {
  font-size: 4.5rem;
  line-height: 5rem;
}

.slider .slider-text {
  margin-top: 1rem;
  font-family: Raleway, sans-serif;
  font-size: 1.75rem;
  font-weight: 300;
}

.slider .slider-text b {
  font-weight: 600;
}

.slider .slider-button {
  position: absolute;
  bottom: 0;
}

.slider .slider-image {
  height: 90%;
}

.slider .slider-image-wrapper {
  flex-shrink: 0;
  width: 40%;
}

.slider .slider-image-wrapper:before {
  content: "x";
  filter: blur(90px);
  z-index: 5;
  background: #fe6300;
  width: 20%;
  height: 20%;
  display: block;
  position: absolute;
  top: 70%;
  left: 75%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider .slider-image-wrapper img {
  object-fit: contain;
  z-index: 10;
  position: relative;
}

@media screen and (width <= 1024px) {
  .slider .slider-slide {
    height: 320px;
  }

  .slider .slider-left-arrow {
    box-sizing: content-box !important;
    width: 1rem !important;
    padding: .5rem !important;
    left: 0 !important;
  }

  .slider .slider-right-arrow {
    padding-right: 2rem;
    right: calc(50% - 512px - 1rem);
    box-sizing: content-box !important;
    width: 1rem !important;
    padding: .5rem !important;
    right: 0 !important;
  }

  .slider .slider-title {
    min-height: 6rem;
    font-size: 2.5rem;
    line-height: 3.2rem;
  }

  .slider .slider-title-wrapper span {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .slider .slider-slide:nth-child(4) .slider-title {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .slider .slider-slide:nth-child(6) .slider-title {
    font-size: 4rem;
    line-height: 4rem;
  }

  .slider .slider-text {
    margin-top: 1rem;
    font-family: Raleway, sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
  }
}

@media screen and (width <= 768px) {
  .slider .slider-content-wrapper {
    padding: 0 1rem;
  }

  .slider .slider-slide:nth-child(4) .slider-title {
    font-size: 1.8rem;
    line-height: 2rem;
  }

  .slider .slider-slide:nth-child(4) img {
    height: 80%;
  }

  .slider .slider-text {
    margin-top: 1rem;
    font-family: Raleway, sans-serif;
    font-size: 1rem;
    font-weight: 300;
  }
}

@media screen and (width <= 640px) {
  .slider {
    padding: 0 0 1rem !important;
  }

  .slider .slider-slide {
    height: 620px;
  }

  .slider .slider-title {
    height: 8rem;
    font-size: 2.5rem;
    line-height: 3.2rem;
  }

  .slider .slider-title-wrapper span {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .slider .slider-content-wrapper {
    padding: 0 1rem;
  }

  .slider .slider-slide:nth-child(4) .slider-title {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .slider .slider-slide:nth-child(6) .slider-title {
    font-size: 4rem;
    line-height: 4rem;
  }

  .slider .slider-content {
    width: 100%;
  }

  .slider .slider-button {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .slider .slider-text {
    margin-top: 20rem;
    font-family: Raleway, sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
  }

  .slider .slider-text br {
    display: none;
  }

  .slider .slider-image-wrapper {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    display: flex;
    position: absolute;
    top: 8rem;
    left: 0;
  }

  .slider .slider-image-wrapper:before {
    filter: blur(90px);
    background: #fe6300;
    width: 40%;
    height: 40%;
    top: 60%;
    left: 60%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media screen and (width <= 400px) {
  .slider .slider-slide {
    height: 525px;
  }

  .slider .slider-title {
    min-height: 4rem;
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .slider .slider-title-wrapper span {
    margin-left: .5rem;
    font-size: 1.3rem;
    line-height: 2rem;
  }

  .slider .slider-slide:nth-child(4) .slider-title {
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .slider .slider-slide:nth-child(6) .slider-title {
    font-size: 4rem;
    line-height: 4rem;
  }

  .slider .slider-text {
    margin-top: 15rem;
    font-family: Raleway, sans-serif;
    font-size: 1rem;
    font-weight: 300;
  }

  .slider .slider-image-wrapper {
    height: 16rem;
    top: 6.5rem;
  }
}

.call-banner-dialog {
  z-index: 100;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.call-banner-dialog .call-banner {
  width: 90%;
  max-width: 900px;
  margin: 0;
  position: relative;
}

@media screen and (width <= 768px) {
  .call-banner-dialog .call-banner {
    width: 100%;
  }
}

.dialog-close {
  z-index: 110;
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  padding: .2rem;
  transition: all .24s;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

@media screen and (pointer: fine) {
  .dialog-close:hover {
    padding: 0;
  }
}

@media screen and (width <= 768px) {
  .dialog-close .call-banner {
    width: 100%;
  }

  .dialog-close .dialog-close {
    top: -2.5rem;
    right: 1.2rem;
  }
}

.demo-popup-dialog {
  z-index: 100;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.demo-popup-dialog .demo-popup {
  width: 600px;
  margin: 0 auto;
  position: relative;
}

.demo-popup-dialog .demo-popup .demo-popup-wrapper {
  background-color: #ececec;
  border-radius: 20px;
  max-width: 1024px;
  margin: 0 auto;
  padding: 2rem 2rem 2.75rem;
}

.demo-popup-dialog .demo-popup .demo-popup-wrapper h4 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.demo-popup-dialog .demo-popup .demo-popup-wrapper .main-button {
  cursor: pointer;
  margin-top: 1rem;
  display: block;
}

@media screen and (width <= 640px) {
  .demo-popup-dialog .demo-popup {
    width: 100%;
    margin: 0 1rem;
  }
}

.call-banner {
  margin: 0 auto 6rem;
}

.call-banner .call-banner-wrapper {
  z-index: 10;
  background-color: #ececec;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  padding: 2rem 2rem 2.75rem;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 23px 14px #0000000d;
}

.call-banner .call-banner-wrapper .call-banner-success {
  color: #fff;
  background: #fe6301;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.call-banner .call-banner-wrapper .call-banner-success .call-banner-title {
  font-size: 3rem;
}

.call-banner .call-banner-wrapper .call-banner-success p {
  text-align: center;
}

@media screen and (width <= 768px) {
  .call-banner .call-banner-wrapper .call-banner-success .call-banner-title {
    font-size: 2rem;
  }
}

.call-banner .call-banner-title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: Oswald, sans-serif;
  font-size: 2rem;
  font-weight: 400;
}

.call-banner .call-banner-content {
  align-items: center;
  column-gap: 3rem;
  display: flex;
}

.call-banner .call-banner-input {
  background: #e0dfdf;
  border: none;
  border-radius: .875rem;
  outline: none;
  width: 21rem;
  padding: .7rem 1.8rem;
  font-size: 1.875rem;
  box-shadow: inset 0 4px 4px #00000040;
}

@media screen and (width <= 480px) {
  .call-banner .call-banner-input {
    padding: .7rem 1.5rem;
  }
}

.call-banner .call-banner-footer {
  margin-top: 2rem;
}

.call-banner .call-banner-checkbox-label {
  cursor: pointer;
  background: #e4e4e4;
  border-radius: .875rem;
  align-items: center;
  column-gap: .5rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  display: flex;
}

.call-banner .call-banner-checkbox-label a {
  color: #000;
  text-decoration: underline;
}

.call-banner .call-banner-button {
  color: #fff;
  letter-spacing: .02rem;
  border-radius: .85rem;
  padding: .6rem 2rem;
  font-size: 1.5rem;
}

@media screen and (width <= 768px) {
  .call-banner .call-banner-title {
    font-size: .95rem;
  }

  .call-banner .call-banner-wrapper {
    margin: 0 1.25rem;
    padding: 1.5rem 1rem;
  }

  .call-banner .call-banner-input {
    width: 16rem;
    font-size: 1.4rem;
  }

  .call-banner .call-banner-content {
    flex-direction: column;
    row-gap: 1rem;
  }

  .call-banner .call-banner-footer {
    margin-top: 1rem;
  }

  .call-banner .call-banner-button {
    font-size: .95rem;
  }

  .call-banner .call-banner-checkbox-label {
    font-size: .85rem;
  }
}

.services-cards {
  width: 100%;
  max-width: 860px;
  margin: 0 auto 6rem;
}

.services-cards .card:first-child svg path {
  fill: #fe6300;
}

.services-cards .card:nth-child(2) svg path {
  fill: #fe9d00;
}

.services-cards .card:nth-child(3) svg path {
  fill: #fe3300;
}

.services-cards .card:nth-child(4) svg path {
  fill: #fb3245;
}

.services-cards .card:nth-child(5) svg path {
  fill: #f6d30b;
}

.services-cards .card:nth-child(6) svg path {
  fill: #ff6b2c;
}

.services-cards .card:nth-child(7) svg path {
  fill: #f2b200;
}

.services-cards .cards-list {
  flex-wrap: wrap;
  row-gap: 10rem;
  margin-top: -3rem;
  padding-top: 5rem;
  display: flex;
}

.services-cards .card {
  cursor: pointer;
  background: #f7f7f7;
  border-radius: 1rem;
  flex-direction: column;
  align-items: start;
  width: calc(33.3333% - 2rem);
  margin-right: 3rem;
  padding: 1.5rem 1.6rem 1.8rem;
  transition: all .17s ease-in-out;
  display: flex;
  position: relative;
  box-shadow: 0 4px 100px #0000001a;
}

@media (pointer: fine) {
  .services-cards .card:hover {
    z-index: 100;
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    box-shadow: 0 4px 100px #0003;
  }
}

.services-cards .card .card-icon {
  object-fit: contain;
  filter: drop-shadow(-6px 4px 3rem #000);
  z-index: 10;
  position: absolute;
  top: 0;
  right: 1rem;
}

.services-cards .card .card-number {
  color: #eaeaea;
  margin-bottom: .5rem;
  font-family: Open Sans, sans-serif;
  font-size: 4.5rem;
  font-weight: 700;
}

.services-cards .card .card-title {
  color: #000;
  height: 2.6rem;
  margin-bottom: 1.5rem;
  font-family: Open Sans, sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.3rem;
}

.services-cards .card .card-desc {
  font-size: .9rem;
}

.services-cards .card svg {
  position: absolute;
  bottom: calc(100% - 12rem);
  right: -1px;
}

.services-cards .card:nth-child(3) {
  margin-right: 0;
}

.services-cards .card:nth-child(n+4) {
  width: calc(25% - 1.5rem);
  margin-right: 2rem;
  padding: 1rem .6rem 1rem 1.2rem;
}

.services-cards .card:nth-child(n+4) .card-number {
  margin-bottom: 1rem;
  font-size: 3.5rem;
}

.services-cards .card:nth-child(n+4) .card-title {
  font-size: .9rem;
  line-height: 1.1rem;
}

.services-cards .card:nth-child(n+4) .card-desc {
  font-size: .7rem;
  line-height: .9rem;
}

.services-cards .card:nth-child(n+4) svg {
  width: 6rem;
  height: 14rem;
  bottom: calc(100% - 8rem);
}

.services-cards .card:last-child {
  margin-right: 0;
}

@media screen and (width <= 878px) {
  .services-cards {
    padding: 0 1.25rem;
  }

  .services-cards .cards-list {
    row-gap: 6rem;
    margin-top: -4rem;
  }

  .services-cards .card {
    padding: 1rem .6rem 1rem 1.2rem;
    width: calc(50% - .75rem) !important;
    margin-right: 1.5rem !important;
  }

  .services-cards .card img {
    width: 40%;
    max-height: 40%;
  }

  .services-cards .card:nth-child(2n) {
    margin-right: 0 !important;
  }

  .services-cards .card .card-title {
    color: #000;
    margin-bottom: 1rem;
    height: auto !important;
    min-height: 2.5rem !important;
    font-size: .9rem !important;
    line-height: 1.1rem !important;
  }

  .services-cards .card .card-number {
    font-size: 3.5rem;
    line-height: 4rem;
    margin-bottom: .5rem !important;
  }

  .services-cards .card .card-desc {
    font-size: .7rem;
    line-height: .9rem;
  }

  .services-cards .card svg {
    width: 85px;
    bottom: calc(100% - 10rem);
  }

  .services-cards .card:nth-child(n+7) {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .services-cards .card:nth-child(n+7) .card-title {
    min-height: auto !important;
  }

  .services-cards .card:nth-child(n+7) img {
    width: 100px;
  }
}

@media screen and (width <= 640px) {
  .services-cards {
    margin: 2rem auto;
  }

  .services-cards .cards-list {
    margin-top: 0;
  }

  .services-cards .card .card-title {
    min-height: unset;
    font-size: .7rem !important;
    line-height: .9rem !important;
  }

  .services-cards .card {
    padding: 1rem .6rem 1rem 1rem;
  }
}

@media screen and (width <= 480px) {
  .services-cards .card:nth-child(3) .card-icon {
    top: -1.5rem;
  }
}

.services-list .service {
  border-top-right-radius: 4rem;
  padding: 5rem 3rem;
  position: relative;
}

.services-list .service ul li {
  margin-bottom: .5rem;
  margin-left: 2.5rem;
  font-size: 1.4rem;
  font-weight: 500;
  position: relative;
}

.services-list .service ul li:last-child {
  margin-bottom: 0;
}

.services-list .service ul li:before {
  content: "";
  background: #fe6300;
  border-radius: .2rem;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  position: absolute;
  top: .5rem;
  left: -2.3rem;
}

@media screen and (width <= 1024px) {
  .services-list .service {
    padding: 2rem 1.25rem;
  }
}

@media screen and (width <= 878px) {
  .services-list .service ul li {
    font-size: 1rem;
    line-height: 1.4;
  }

  .services-list .service ul li:before {
    top: .2rem;
  }
}

@media screen and (width <= 640px) {
  .services-list .service {
    background: unset !important;
    box-shadow: none !important;
  }
}

.services-list .service-super-mega-designer-path {
  position: absolute;
  bottom: 2rem;
  left: 0;
}

@media screen and (width <= 1024px) {
  .services-list .service-super-mega-designer-path {
    bottom: unset !important;
    top: 6rem !important;
  }
}

@media screen and (width <= 640px) {
  .services-list .service-super-mega-designer-path {
    width: 35%;
    top: -4.5rem !important;
    bottom: unset !important;
  }
}

.services-list #service-01 .service-super-mega-designer-path {
  display: inline-block !important;
}

.services-list #service-01, .services-list #service-03, .services-list #service-05, .services-list #service-07 {
  background: #e8e8e8;
  box-shadow: 0 23px 14px #0000000d;
}

.services-list #service-02 .service-header-data {
  padding: 0;
}

.services-list #service-02 .service-number, .services-list #service-04 .service-number, .services-list #service-06 .service-number {
  color: #e8e8e8;
}

.services-list #service-01 .service-super-mega-designer-path {
  fill: #fd6300;
  bottom: 11rem;
}

@media screen and (width <= 1024px) {
  .services-list #service-01 .service-super-mega-designer-path {
    bottom: unset;
    top: 6rem;
  }
}

.services-list #service-01 ul li:before {
  background: #fd6300;
}

.services-list #service-02 .service-super-mega-designer-path {
  fill: #fe9d00;
  bottom: 11rem;
}

.services-list #service-02 ul li:before {
  background: #fe9d00;
}

.services-list #service-03 .service-super-mega-designer-path {
  fill: #fe3300;
}

.services-list #service-03 ul li:before {
  background: #fe3300;
}

.services-list #service-04 .service-super-mega-designer-path {
  fill: #f93346;
  bottom: 22rem;
}

.services-list #service-04 ul li:before {
  background: #f93346;
}

.services-list #service-05 .service-super-mega-designer-path {
  fill: #ff6b2c;
  bottom: 7rem;
}

.services-list #service-05 ul li:before {
  background: #ff6b2c;
}

.services-list #service-06 .service-super-mega-designer-path {
  fill: #f6d30b;
  bottom: 5rem;
}

.services-list #service-06 ul li:before {
  background: #f6d30b;
}

.services-list #service-07 .service-super-mega-designer-path {
  fill: #f2b200;
  bottom: 7rem;
}

.services-list #service-07 ul li:before {
  background: #f2b200;
}

.services-list .service-content-wrapper {
  max-width: 1024px;
  margin: 0 auto;
}

.services-list .service-content {
  position: relative;
}

.services-list .service-number {
  color: #e3e3e3;
  z-index: 0;
  font-size: 30rem;
  font-weight: 700;
  line-height: 24rem;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (width <= 1024px) {
  .services-list .service-number {
    font-size: 24rem;
    line-height: 22rem;
  }
}

@media screen and (width <= 640px) {
  .services-list .service-number {
    display: none;
  }
}

.services-list .service-header {
  align-items: flex-start;
  display: flex;
  position: relative;
}

.services-list .service-header .service-header-image {
  z-index: 1;
}

@media screen and (width <= 640px) {
  .services-list .service-header .service-header-image {
    display: none;
  }
}

.services-list .service-header .mobile-service-header-image {
  border-radius: 3rem;
}

@media screen and (width >= 641px) {
  .services-list .service-header .mobile-service-header-image {
    display: none;
  }
}

.services-list .service-header .service-header-data {
  z-index: 1;
  padding: 2rem 1rem;
}

.services-list .service-header .service-header-data .service-header-title {
  text-transform: uppercase;
  font-family: Oswald, sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
}

@media screen and (width <= 1024px) {
  .services-list .service-header .service-header-data {
    margin: 0 !important;
    padding: 2rem 1rem 0 !important;
  }
}

@media screen and (width <= 640px) {
  .services-list .service-header .service-header-data {
    margin: 0 !important;
    padding: 2rem 0 0 !important;
  }
}

.services-list .service-header .ochen-krasivo-narisonaniy-sharik {
  background: linear-gradient(230.08deg, #ffc39c 3.99%, #fe6300 75.76%);
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 23px 14px #0000000d;
}

.services-list .service-header .ochen-krasivo-narisonaniy-sharik-01 {
  width: 1rem;
  height: 1rem;
  top: 12rem;
  right: .2rem;
}

.services-list .service-header .ochen-krasivo-narisonaniy-sharik-02 {
  width: 2rem;
  height: 2rem;
  top: 15rem;
  right: 2.2rem;
}

.services-list .service-header .ochen-krasivo-narisonaniy-sharik-03 {
  width: 4rem;
  height: 4rem;
  top: 18rem;
  right: 7rem;
}

@media screen and (width <= 1024px) {
  .services-list .service-header {
    flex-direction: column;
  }

  .services-list .service-header .service-header-title {
    font-size: 3rem !important;
  }

  .services-list .service-header .service-header-decoration {
    display: none;
  }
}

@media screen and (width <= 878px) {
  .services-list .service-header {
    flex-direction: column;
  }

  .services-list .service-header .service-header-title {
    font-size: 2rem !important;
  }

  .services-list .service-header .service-header-decoration {
    display: none;
  }
}

@media screen and (width <= 640px) {
  .services-list .service-header .service-header-title {
    font-size: 1.6rem !important;
  }
}

.services-list #service-01 .service-header-data {
  margin-left: 1.5rem;
}

@media screen and (width <= 1024px) {
  .services-list .service-header-body {
    display: none !important;
  }
}

.services-list .service-footer {
  justify-content: flex-end;
  margin: 4rem 0 0;
  display: flex;
  position: relative;
}

.services-list .service-footer .main-button {
  z-index: 10;
}

.services-list .service-footer .more-button {
  color: #fe6300;
  background: none;
  padding: 1rem;
  font-size: 1.25rem;
  text-decoration: underline;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.services-list .service-footer .more-button-wrapper:hover .more-popover-wrapper {
  opacity: 1;
  display: block;
}

.services-list .service-footer .more-popover-wrapper {
  z-index: 10;
  opacity: 0;
  filter: drop-shadow(-6px 4px 4rem #0006);
  width: 90%;
  transition: all .23s;
  display: none;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.services-list .service-footer .more-popover-wrapper svg {
  margin-top: -4px;
  position: relative;
  bottom: 0;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.services-list .service-footer .more-popover-wrapper .more-popover {
  background: #fff;
  border-radius: 1rem;
  flex-direction: column;
  padding: 2rem;
  display: flex;
}

.services-list .service-footer .more-popover-wrapper .more-popover-container img {
  float: left;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.services-list .service-footer .more-popover-wrapper .more-popover-container p {
  margin-top: 0;
  font-size: 1rem;
}

.services-list .service-footer .more-popover-wrapper .more-popover-container ul {
  margin: .5rem 0;
}

.services-list .service-footer .more-popover-wrapper .more-popover-container ul li {
  margin-left: 1.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.services-list .service-footer .more-popover-wrapper .more-popover-container ul li:before {
  width: .5rem;
  height: .5rem;
  left: -1.4rem;
}

@media screen and (width <= 878px) {
  .services-list .service-footer .more-popover-wrapper, .services-list .service-footer .more-button {
    display: none;
  }
}

@media screen and (width <= 640px) {
  .services-list .service-footer {
    padding: 2rem 0 0;
  }

  .services-list .service-footer .main-button {
    width: 100%;
  }
}

.services-list .service-body {
  margin-top: 3rem;
}

@media screen and (width <= 878px) {
  .services-list .service-body {
    margin-top: 2rem;
  }
}

@media screen and (width >= 1024px) {
  .services-list .service-02-body {
    margin-top: 8rem;
  }
}

.services-list .service-02-body span {
  font-size: 1rem !important;
}

.services-list .service-01-body .service-01-body-data, .services-list .service-02-body .service-01-body-data {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.services-list .service-01-body .service-01-body-data-left .service-01-body-data-list, .services-list .service-02-body .service-01-body-data-left .service-01-body-data-list {
  z-index: 5;
  align-items: flex-end;
}

.services-list .service-01-body .service-01-body-data-right .service-01-body-data-list, .services-list .service-02-body .service-01-body-data-right .service-01-body-data-list {
  z-index: 5;
  align-items: flex-start;
}

.services-list .service-01-body .service-01-body-data-left, .services-list .service-01-body .service-01-body-data-right, .services-list .service-02-body .service-01-body-data-left, .services-list .service-02-body .service-01-body-data-right {
  display: flex;
}

.services-list .service-01-body .service-01-body-data-list, .services-list .service-02-body .service-01-body-data-list {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.services-list .service-01-body .service-01-body-data-list > *, .services-list .service-02-body .service-01-body-data-list > * {
  flex: 1 0 33%;
}

.services-list .service-01-body .service-01-body-data-list > :first-child, .services-list .service-02-body .service-01-body-data-list > :first-child {
  align-items: flex-start;
  display: flex;
}

.services-list .service-01-body .service-01-body-data-list > :nth-child(2), .services-list .service-02-body .service-01-body-data-list > :nth-child(2) {
  align-items: center;
  display: flex;
}

.services-list .service-01-body .service-01-body-data-list > :nth-child(3), .services-list .service-02-body .service-01-body-data-list > :nth-child(3) {
  align-items: end;
  display: flex;
}

.services-list .service-01-body span, .services-list .service-02-body span {
  font-size: 1.25rem;
  font-weight: 700;
}

.services-list .service-01-body svg, .services-list .service-02-body svg {
  z-index: 5;
  margin: 0 1rem;
  padding: .5rem 0;
}

.services-list .service-01-body-example {
  position: relative;
}

.services-list .service-01-body-example .main-button {
  padding: .75rem 3rem;
  font-size: 1.5rem;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (width <= 640px) {
  .services-list .service-01-body-example {
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .services-list .service-01-body-example button {
    bottom: 1.5rem;
  }
}

.services-list .service-01-body-data-mobile {
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.services-list .service-01-body-data-mobile img {
  flex-shrink: 0;
}

@media screen and (width <= 640px) {
  .services-list .service-01-body-data-mobile {
    flex-direction: column;
    align-items: start;
  }

  .services-list .service-01-body-data-mobile li br {
    display: none;
  }

  .services-list .service-01-body-data-mobile img {
    object-fit: contain;
    height: 400px;
    width: 100% !important;
    margin: 2rem 0 0 !important;
  }
}

@media screen and (width >= 1024px) {
  .services-list .service-04-body {
    margin-top: 5rem;
  }

  .services-list .service-04-body ul {
    margin-left: 160px;
  }
}

.services-list .service-04-body p {
  margin-top: 4rem;
  font-size: 1.4rem;
  font-weight: 500;
}

@media screen and (width >= 1024px) {
  .services-list .service-05-body {
    margin-top: 5rem;
  }

  .services-list .service-05-body ul {
    margin-top: 4rem;
    margin-left: 160px;
    font-size: 1.4rem;
    font-weight: 500;
  }
}

.footer-banner-wrapper {
  background: #f2b200;
  padding: 1rem;
}

.scroll-to-top {
  z-index: 10;
  cursor: pointer;
  background: #fff;
  border-radius: .5rem;
  padding: .25rem;
  display: flex;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  box-shadow: 0 3.81px 8.57px #0000001a;
}

.scroll-to-top img {
  width: 2.5rem;
  height: 2.5rem;
}

.footer-banner {
  justify-content: space-between;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
}

.footer-banner .footer-link {
  color: #fff;
  background: #d69d00;
  border-radius: .5rem;
  align-items: center;
  column-gap: .5rem;
  padding: .5rem 1rem;
  display: flex;
}

@media screen and (width <= 640px) {
  .footer-banner {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
  }
}

.header-logo {
  height: 68px;
}
/*# sourceMappingURL=index.29aafb92.css.map */
