//import scss file
@use "./gradient-text.scss";

html {
  font-size: 16px;
}

//You can write css or scss
body {
  font-family: "Open Sans", "Oswald", sans-serif;
  color: #030303;
  background: #E3E2E2;
}

a {
  text-decoration: none;
}

.hidden {
  display: none !important;
}

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 200 to 700

.oswald-font {
  font-family: "Oswald", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.os-font {
  font-family: "Open Sans", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.hidden-md {
  @media screen and (max-width: 1024px) {
    display: none !important;
  }
}

.hidden-sm {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.hidden-xs {
  @media screen and (max-width: 640px) {
    display: none !important;
  }
}

.visible-md {
  @media screen and (min-width: 1024px) {
    display: none !important;
  }
}

.visible-sm {
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

.visible-xs {
  @media screen and (min-width: 640px) {
    display: none !important;
  }
}

button {
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: all 0.13s ease-out;

  &[disabled] {
    background: rgba(157, 158, 158, 1);
    cursor: not-allowed;
  }

  @media (pointer: fine) {
    &:hover:not([disabled]) {
      transform: scale(1.02);
    }
  }
}

.page-wrapper {
  overflow: hidden;
  position: relative;
}

.designed-path {
  position: absolute;
  z-index: 0;
  top: 100rem;
  right: 0;

  &:nth-child(1) {
    top: 4rem;
    left: -40rem;
  }

  &:nth-child(2) {
    top: -20rem;
    left: -15rem;
  }

  &:nth-child(3) {
    top: -4rem;
    right: -20rem;
  }

  &:nth-child(4) {
    top: 50rem;
    left: -40rem;
  }

  &:nth-child(5) {
    top: 50rem;
    right: -30rem;
  }
}

.header-wrapper {
  margin: 2.5rem 1rem;
  position: relative;
  z-index: 2;

  .header {
    margin: 0 auto;
    background-color: #808080;
    border-radius: 13px;
    max-width: 1024px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .header-additional {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
  }

  .header-additional-button {
    background: linear-gradient(180deg, #FE6301 0%, #983B01 258.33%);
    border-radius: 8px;
    border: 1px solid #E25800;
    color: white;
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.5rem 0.875rem;

    &--tg {
      background: linear-gradient(181.07deg, #00C0FF 0.81%, #0072DB 103.9%);
      border: 1px solid #0081EF;
    }
  }

  .header-contact-phone {
    font-size: 1.25rem;
    color: #FFF;
  }

  @media screen and (max-width: 768px) {
    margin: 1rem 1rem 2rem;

    .header {
      padding: 0;
      background: none;
    }

    .header-logo {
      height: 2rem;
    }

    .header-additional {
      column-gap: 0.5rem;
    }

    .header-logo-gl {
      height: 2rem;
    }
  }
}

.main-button {
  background: rgba(254, 99, 0, 1);
  font-size: 1.75rem;
  line-height: 2rem;
  color: #fff;
  padding: 0.75rem 2rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0px 3.81px 8.57px 0px rgba(0, 0, 0, 0.1);
}

.slider {
  width: 100%;
  padding: 0 0 8rem !important;

  .swiper-pagination {
    position: absolute;
    bottom: 5.5rem !important;
  }

  .swiper-pagination-bullet-active {
    background: #FFF;
  }

  .swiper-wrapper {
    position: relative;
  }

  .slider-arrow {
    position: absolute;
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: scale(1.2);
    }
  }

  .slider-left-arrow {
    left: calc(50% - (1024px / 2) - 1rem);
    padding-left: 2rem;
  }

  .slider-right-arrow {
    right: calc(50% - (1024px / 2) - 1rem);
    padding-right: 2rem;
  }

  .slider-slide {
    width: 100%;
    height: 380px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .slider-content-wrapper {
    box-sizing: border-box;
    padding: 0 3rem;
    max-width: 1024px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .slider-content {
    position: relative;
  }

  .slider-title-wrapper {
    display: flex;
    align-items: flex-start;

    span {
      font-family: "Oswald", serif;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 300;
      margin-left: 1.2rem;
    }
  }


  .slider-title {
    font-size: 3.75rem;
    line-height: 4.5rem;
    min-height: 9rem;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
  }

  .slider-slide:nth-child(4) .slider-title {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .slider-slide:nth-child(6) .slider-title {
    font-size: 4.5rem;
    line-height: 5rem;
  }

  .slider-text {

    b {
      font-weight: 600;
    }

    font-family: "Raleway", sans-serif;
    margin-top: 1rem;
    font-size: 1.75rem;
    font-weight: 300;
  }

  .slider-button {
    position: absolute;
    bottom: 0;
  }

  .slider-image {
    height: 90%;
  }

  .slider-image-wrapper {
    flex-shrink: 0;
    width: 40%;

    &:before {
      content: "x";
      display: block;
      width: 20%;
      height: 20%;
      left: 75%;
      top: 70%;
      transform: translate(-50%, -50%);
      background: rgba(254, 99, 0, 1);
      filter: blur(90px);
      position: absolute;
      z-index: 5;
    }

    & img {
      object-fit: contain;
      position: relative;
      z-index: 10;
    }
  }

  @media screen and (max-width: 1024px) {
    .slider-slide {
      height: 320px;
    }

    .slider-left-arrow {
      left: 0 !important;
      width: 1rem !important;
      box-sizing: content-box !important;
      padding: 0.5rem !important;
    }

    .slider-right-arrow {
      right: 0 !important;
      width: 1rem !important;
      box-sizing: content-box !important;
      padding: 0.5rem !important;
    }

    .slider-right-arrow {
      right: calc(50% - (1024px / 2) - 1rem);
      padding-right: 2rem;
    }

    .slider-title {
      font-size: 2.5rem;
      line-height: 3.2rem;
      min-height: 6rem;
    }

    .slider-title-wrapper {
      span {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .slider-slide:nth-child(4) .slider-title {
      font-size: 2rem;
      line-height: 2.6rem;
    }

    .slider-slide:nth-child(6) .slider-title {
      font-size: 4rem;
      line-height: 4rem;
    }

    .slider-text {
      font-family: "Raleway", sans-serif;
      margin-top: 1rem;
      font-size: 1.2rem;
      font-weight: 300;
    }
  }

  @media screen and (max-width: 768px) {
    .slider-content-wrapper {
      padding: 0 1rem;
    }

    .slider-slide:nth-child(4) .slider-title {
      font-size: 1.8rem;
      line-height: 2rem;
    }

    .slider-slide:nth-child(4) img {
      height: 80%;
    }

    .slider-text {
      font-family: "Raleway", sans-serif;
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 300;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 0 0 1rem !important;

    .slider-slide {
      height: 620px;
    }

    .slider-title {
      font-size: 2.5rem;
      line-height: 3.2rem;
      height: 8rem;
    }

    .slider-title-wrapper {
      span {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .slider-content-wrapper {
      padding: 0 1rem;
    }

    .slider-slide:nth-child(4) .slider-title {
      font-size: 2rem;
      line-height: 2.6rem;
    }

    .slider-slide:nth-child(6) .slider-title {
      font-size: 4rem;
      line-height: 4rem;
    }

    .slider-content {
      width: 100%;
    }

    .slider-button {
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    .slider-text {
      font-family: "Raleway", sans-serif;
      margin-top: 20rem;
      font-size: 1.2rem;
      font-weight: 300;

      br {
        display: none;
      }
    }

    .slider-image-wrapper {
      position: absolute;
      height: 50%;
      width: 100%;
      top: 8rem;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        width: 40%;
        height: 40%;
        left: 60%;
        top: 60%;
        transform: translate(-50%, -50%);
        background: rgba(254, 99, 0, 1);
        filter: blur(90px);
      }

      .slider-image {

      }
    }
  }


  @media screen and (max-width: 400px) {
    .slider-slide {
      height: 525px;
    }

    .slider-title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      min-height: 4rem;
    }

    .slider-title-wrapper {
      span {
        font-size: 1.3rem;
        line-height: 2rem;
        margin-left: 0.5rem;
      }
    }

    .slider-slide:nth-child(4) .slider-title {
      font-size: 2rem;
      line-height: 2.6rem;
    }

    .slider-slide:nth-child(6) .slider-title {
      font-size: 4rem;
      line-height: 4rem;
    }

    .slider-text {
      font-family: "Raleway", sans-serif;
      margin-top: 15rem;
      font-size: 1rem;
      font-weight: 300;
    }

    .slider-image-wrapper {
      top: 6.5rem;
      height: 16rem;
    }
  }
}

.call-banner-dialog {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .call-banner {
    position: relative;
    width: 90%;
    max-width: 900px;
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    .call-banner {
      width: 100%;
    }
  }
}

.dialog-close {
  position: absolute;
  top: 0.5rem;
  width: 2.2rem;
  height: 2.2rem;
  z-index: 110;
  right: 0.5rem;
  padding: 0.2rem;
  background: white;
  border-radius: 50%;
  transition: all 0.24s;
  cursor: pointer;

  @media screen and (pointer: fine) {
    &:hover {
      padding: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .call-banner {
      width: 100%;
    }

    .dialog-close {
      right: 1.2rem;
      top: -2.5rem;
    }
  }
}


.demo-popup-dialog {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .demo-popup {
    width: 600px;
    position: relative;
    margin: 0 auto;

    .demo-popup-wrapper {
      margin: 0 auto;
      padding: 2rem 2rem 2.75rem;
      border-radius: 20px;
      max-width: 1024px;
      background-color: rgba(236, 236, 236, 1);

      h4 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      .main-button {
        cursor: pointer;
        display: block;
        margin-top: 1rem;
      }
    }
  }

  @media screen and (max-width: 640px) {
    .demo-popup {
      width: 100%;
      margin: 0 1rem;
    }
  }
}

.call-banner {
  margin: 0 auto 6rem;

  .call-banner-wrapper {
    margin: 0 auto;
    padding: 2rem 2rem 2.75rem;
    border-radius: 20px;
    max-width: 1024px;
    background-color: rgba(236, 236, 236, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 23px 14px 0px #0000000D;
    position: relative;
    z-index: 10;
    overflow: hidden;

    .call-banner-success {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #fe6301;
      color: #fff;

      .call-banner-title {
        font-size: 3rem;
      }

      p {
        text-align: center;
      }
    }

    @media screen and (max-width: 768px) {
      .call-banner-success .call-banner-title {
        font-size: 2rem;
      }
    }
  }


  .call-banner-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .call-banner-content {
    display: flex;
    align-items: center;
    column-gap: 3rem;
  }

  .call-banner-input {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) inset;
    outline: none;
    border: none;
    background: rgba(224, 223, 223, 1);
    padding: 0.7rem 1.8rem;
    border-radius: 0.875rem;
    font-size: 1.875rem;
    width: 21rem;

    @media screen and (max-width: 480px) {
      padding: 0.7rem 1.5rem;
    }
  }

  .call-banner-footer {
    margin-top: 2rem;
  }

  .call-banner-checkbox-label {
    font-size: 1rem;
    cursor: pointer;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.875rem;
    background: rgb(228, 228, 228);
    display: flex;

    a {
      color: #000;
      text-decoration: underline;
    }
  }

  .call-banner-button {
    font-size: 1.5rem;
    color: #fff;
    border-radius: 0.85rem;
    padding: 0.6rem 2rem;
    letter-spacing: 0.02rem;
  }

  @media screen and (max-width: 768px) {
    .call-banner-title {
      font-size: 0.95rem;
    }

    .call-banner-wrapper {
      margin: 0 1.25rem;
      padding: 1.5rem 1rem;
    }

    .call-banner-input {
      font-size: 1.4rem;
      width: 16rem;
    }

    .call-banner-content {
      flex-direction: column;
      row-gap: 1rem;
    }

    .call-banner-footer {
      margin-top: 1rem;
    }

    .call-banner-button {
      font-size: 0.95rem;
    }

    .call-banner-checkbox-label {
      font-size: 0.85rem;
    }
  }
}

.services-cards {
  width: 100%;
  max-width: 860px;
  margin: 0 auto 6rem;

  .card:nth-child(1) svg path {
    fill: #FE6300;
  }

  .card:nth-child(2) svg path {
    fill: #FE9D00;
  }

  .card:nth-child(3) svg path {
    fill: #FE3300;
  }

  .card:nth-child(4) svg path {
    fill: #FB3245;
  }

  .card:nth-child(5) svg path {
    fill: #F6D30B;
  }

  .card:nth-child(6) svg path {
    fill: #FF6B2C;
  }

  .card:nth-child(7) svg path {
    fill: #F2B200;
  }

  .cards-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10rem;
    padding-top: 5rem;
    margin-top: -3rem;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: start;
    background: #F7F7F7;
    box-shadow: 0 4px 100px 0px rgba(0, 0, 0, 0.1);
    width: calc((100% - 6rem) / 3);
    margin-right: 3rem;
    border-radius: 1rem;
    padding: 1.5rem 1.6rem 1.8rem;
    position: relative;
    cursor: pointer;
    transition: all 0.17s ease-in-out;

    @media (pointer: fine) {
      &:hover {
        transform: scale(1.05);
        z-index: 100;
        box-shadow: 0 4px 100px 0px rgba(0, 0, 0, 0.2);
      }
    }

    .card-icon {
      position: absolute;
      right: 1rem;
      object-fit: contain;
      top: 0;
      filter: drop-shadow(-6px 4px 3rem black);
      z-index: 10;
    }

    .card-number {
      color: #EAEAEA;
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      font-size: 4.5rem;
      margin-bottom: 0.5rem;
    }

    .card-title {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      font-size: 1.1rem;
      color: black;
      line-height: 1.3rem;
      height: 2.6rem;
      margin-bottom: 1.5rem;
    }

    .card-desc {
      font-size: 0.9rem;
    }

    svg {
      position: absolute;
      right: -1px;
      bottom: calc(100% - 12rem);
    }

    &:nth-child(3) {
      margin-right: 0;
    }

    &:nth-child(n+4) {
      width: calc((100% - 6rem) / 4);
      margin-right: 2rem;
      padding: 1rem 0.6rem 1rem 1.2rem;

      .card-number {
        font-size: 3.5rem;
        margin-bottom: 1rem;
      }

      .card-title {
        font-size: 0.9rem;
        line-height: 1.1rem;
      }

      .card-desc {
        font-size: 0.7rem;
        line-height: 0.9rem;
      }

      svg {
        height: 14rem;
        width: 6rem;
        bottom: calc(100% - 8rem);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 878px) {
    padding: 0 1.25rem;

    .cards-list {
      margin-top: -4rem;
      row-gap: 6rem;
    }

    .card {
      width: calc((100% - 1.5rem) / 2) !important;
      margin-right: 1.5rem !important;
      padding: 1rem 0.6rem 1rem 1.2rem;

      img {
        width: 40%;
        max-height: 40%;
      }

      &:nth-child(2n) {
        margin-right: 0 !important;
      }

      .card-title {
        font-size: 0.9rem !important;
        line-height: 1.1rem !important;
        color: black;
        min-height: 2.5rem !important;
        height: auto !important;
        margin-bottom: 1rem;
      }

      .card-number {
        font-size: 3.5rem;
        line-height: 4rem;
        margin-bottom: 0.5rem !important;
      }

      .card-desc {
        font-size: 0.7rem;
        line-height: 0.9rem;
      }

      svg {
        width: 85px;
        bottom: calc(100% - 10rem);
      }

      &:nth-child(n + 7) {
        width: 100% !important;
        margin-right: 0 !important;

        .card-title {
          min-height: auto !important;
        }

        img {
          width: 100px;
        }
      }
    }

  }

  @media screen and (max-width: 640px) {
    margin: 2rem auto 2rem;

    .cards-list {
      margin-top: 0rem;
    }

    .card .card-title {
      font-size: 0.7rem !important;
      line-height: 0.9rem !important;
      min-height: unset;
    }

    .card {
      padding: 1rem 0.6rem 1rem 1rem;
    }
  }

  @media screen and (max-width: 480px) {
    .card {
      &:nth-child(3) .card-icon {
        top: -1.5rem;
      }
    }
  }
}

.services-list {
  .service {
    border-top-right-radius: 4rem;
    padding: 5rem 3rem;
    position: relative;

    ul {
      li {
        position: relative;
        font-size: 1.4rem;
        font-weight: 500;
        margin-left: 2.5rem;
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: "";
          display: inline-block;
          width: 1rem;
          height: 1rem;
          background: #FE6300;
          position: absolute;
          border-radius: 0.2rem;
          top: 0.5rem;
          left: -2.3rem;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      padding: 2rem 1.25rem;
    }

    @media screen and (max-width: 878px) {
      ul li {
        font-size: 1rem;
        line-height: 1.4;

        &:before {
          top: 0.2rem;
        }
      }
    }

    @media screen and (max-width: 640px) {
      background: unset !important;
      box-shadow: none !important;
    }
  }

  .service-super-mega-designer-path {
    position: absolute;
    left: 0;
    bottom: 2rem;

    @media screen and (max-width: 1024px) {
      bottom: unset !important;
      top: 6rem !important;
    }

    @media screen and (max-width: 640px) {
      width: 35%;
      top: -4.5rem !important;
      bottom: unset !important;
    }
  }

  #service-01 .service-super-mega-designer-path {
    display: inline-block !important;
  }

  #service-01, #service-03, #service-05, #service-07 {
    background: #E8E8E8;
    box-shadow: 0px 23px 14px 0px #0000000D;
  }

  #service-02 {
    .service-header-data {
      padding: 0;
    }
  }

  #service-02, #service-04, #service-06 {
    .service-number {
      color: #E8E8E8;
    }
  }

  #service-01 .service-super-mega-designer-path {
    fill: #FD6300;
    bottom: 11rem;

    @media screen and (max-width: 1024px) {
      bottom: unset;
      top: 6rem
    }
  }

  #service-01 ul li:before {
    background: #FD6300;
  }

  #service-02 .service-super-mega-designer-path {
    fill: #FE9D00;
    bottom: 11rem;
  }

  #service-02 ul li:before {
    background: #FE9D00;
  }

  #service-03 .service-super-mega-designer-path {
    fill: #FE3300;
  }

  #service-03 ul li:before {
    background: #FE3300;
  }

  #service-04 .service-super-mega-designer-path {
    fill: #F93346;
    bottom: 22rem;
  }

  #service-04 ul li:before {
    background: #F93346;
  }

  #service-05 .service-super-mega-designer-path {
    fill: #FF6B2C;
    bottom: 7rem;
  }

  #service-05 ul li:before {
    background: #FF6B2C;
  }

  #service-06 .service-super-mega-designer-path {
    fill: #F6D30B;
    bottom: 5rem;
  }

  #service-06 ul li:before {
    background: #F6D30B;
  }

  #service-07 .service-super-mega-designer-path {
    fill: #F2B200;
    bottom: 7rem;
  }

  #service-07 ul li:before {
    background: #F2B200;
  }

  .service-content-wrapper {
    margin: 0 auto;
    max-width: 1024px;
  }

  .service-content {
    position: relative;
  }

  .service-number {
    font-weight: 700;
    font-size: 30rem;
    line-height: 24rem;
    position: absolute;
    color: #E3E3E3;
    z-index: 0;
    right: 0;
    top: 0;

    @media screen and (max-width: 1024px) {
      font-size: 24rem;
      line-height: 22rem;
    }

    @media screen and (max-width: 640px) {
      display: none;
    }
  }

  .service-header {
    display: flex;
    position: relative;
    align-items: flex-start;

    .service-header-image {
      z-index: 1;
      @media screen and (max-width: 640px) {
        display: none;
      }
    }

    .mobile-service-header-image {
      border-radius: 3rem;
      @media screen and (min-width: 641px) {
        display: none;
      }
    }

    .service-header-data {
      z-index: 1;
      padding: 2rem 1rem;

      .service-header-title {
        font-family: "Oswald", sans-serif;
        font-weight: 500;
        font-size: 2.5rem;
        text-transform: uppercase;
      }

      @media screen and (max-width: 1024px) {
        margin: 0 !important;
        padding: 2rem 1rem 0 !important;
      }

      @media screen and (max-width: 640px) {
        margin: 0 !important;
        padding: 2rem 0 0 !important;
      }
    }

    .ochen-krasivo-narisonaniy-sharik {
      position: absolute;
      background: linear-gradient(230.08deg, #FFC39C 3.99%, #FE6300 75.76%);
      border-radius: 50%;
      box-shadow: 0 23px 14px 0 #0000000D;

      &-01 {
        top: 12rem;
        right: 0.2rem;
        width: 1rem;
        height: 1rem;
      }

      &-02 {
        top: 15rem;
        right: 2.2rem;
        width: 2rem;
        height: 2rem;
      }

      &-03 {
        top: 18rem;
        right: 7rem;
        width: 4rem;
        height: 4rem;
      }
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;

      .service-header-title {
        font-size: 3rem !important;
      }

      .service-header-decoration {
        display: none;
      }
    }

    @media screen and (max-width: 878px) {
      flex-direction: column;

      .service-header-title {
        font-size: 2rem !important;
      }

      .service-header-decoration {
        display: none;
      }
    }

    @media screen and (max-width: 640px) {
      .service-header-title {
        font-size: 1.6rem !important;
      }
    }
  }

  #service-01 .service-header-data {
    margin-left: 1.5rem;
  }

  .service-header-body {
    @media screen and (max-width: 1024px) {
      display: none !important;
    }
  }

  .service-footer {
    margin: 4rem 0 0;
    display: flex;
    justify-content: flex-end;
    position: relative;

    .main-button {
      z-index: 10;
    }

    .more-button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #FE6300;
      font-size: 1.25rem;
      padding: 1rem;
      text-decoration: underline;
      background: none;
    }

    .more-button-wrapper {
      &:hover {
        .more-popover-wrapper {
          display: block;
          opacity: 1;
        }
      }
    }

    .more-popover-wrapper {
      position: absolute;
      display: none;
      z-index: 10;
      bottom: 3rem;
      opacity: 0;
      width: 90%;
      filter: drop-shadow(-6px 4px 4rem rgba(0, 0, 0, 0.4));
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.23s;

      svg {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        margin-top: -4px;
      }

      .more-popover {
        border-radius: 1rem;
        background: white;
        padding: 2rem;
        display: flex;
        flex-direction: column;
      }

      .more-popover-container {
        img {
          float: left;
          margin-right: 1rem;
          margin-bottom: 1rem;
        }

        p {
          font-size: 1rem;
          margin-top: 0; /* убрать отступ сверху */
        }

        ul {
          margin: 0.5rem 0;
        }

        ul li {
          font-size: 1rem;
          margin-left: 1.5rem;
          font-weight: 400;

          &:before {
            width: 0.5rem;
            height: 0.5rem;
            left: -1.4rem;
          }
        }
      }
    }

    @media screen and (max-width: 878px) {
      .more-popover-wrapper {
        display: none;
      }

      .more-button {
        display: none;
      }
    }
    @media screen and (max-width: 640px) {
      padding: 2rem 0 0;


      .main-button {
        width: 100%;
      }
    }
  }

  .service-body {
    margin-top: 3rem;

    @media screen and (max-width: 878px) {
      margin-top: 2rem;
    }
  }

  .service-02-body {

    @media screen and (min-width: 1024px) {
      margin-top: 8rem;
    }

    span {
      font-size: 1rem !important;
    }
  }

  .service-01-body, .service-02-body {
    .service-01-body-data {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .service-01-body-data-left .service-01-body-data-list {
      z-index: 5;
      align-items: flex-end;
    }

    .service-01-body-data-right .service-01-body-data-list {
      z-index: 5;
      align-items: flex-start;
    }

    .service-01-body-data-left, .service-01-body-data-right {
      display: flex;
    }

    .service-01-body-data-list {
      display: flex;
      justify-content: space-between;
      flex-direction: column
    }

    .service-01-body-data-list > * {
      flex: 1 0 33%;
    }

    .service-01-body-data-list > *:nth-child(1) {
      display: flex;
      align-items: flex-start;
    }

    .service-01-body-data-list > *:nth-child(2) {
      display: flex;
      align-items: center;
    }

    .service-01-body-data-list > *:nth-child(3) {
      display: flex;
      align-items: end;
    }

    span {
      font-size: 1.25rem;
      font-weight: 700;
    }

    svg {
      z-index: 5;
      padding: 0.5rem 0;
      margin: 0 1rem;
    }
  }

  .service-01-body-example {
    position: relative;

    .main-button {
      position: absolute;
      font-size: 1.5rem;
      padding: 0.75rem 3rem;
      left: 50%;
      bottom: 1rem;
      transform: translateX(-50%);
    }

    @media screen and (max-width: 640px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        bottom: 1.5rem;
      }
    }
  }

  .service-01-body-data-mobile {
    display: flex;
    align-items: center;
    justify-content: space-around;

    img {
      flex-shrink: 0;
    }

    @media screen and (max-width: 640px) {
      align-items: start;
      flex-direction: column;

      li br {
        display: none;
      }

      img {
        width: 100% !important;
        height: 400px;
        object-fit: contain;
        margin: 2rem 0 0 !important;
      }
    }
  }

  .service-04-body {
    @media screen and (min-width: 1024px) {
      margin-top: 5rem;
      ul {
        margin-left: 160px;
      }
    }

    p {
      margin-top: 4rem;
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  .service-05-body {
    @media screen and (min-width: 1024px) {
      margin-top: 5rem;
      ul {
        margin-left: 160px;
        margin-top: 4rem;
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
  }
}

.footer-banner-wrapper {
  padding: 1rem;
  background: #f2b200;
}

.scroll-to-top {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 10;
  background: white;
  display: flex;
  padding: 0.25rem;
  border-radius: 0.5rem;
  box-shadow: 0px 3.81px 8.57px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  img {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.footer-banner {
  display: flex;
  align-items: center;
  max-width: 1024px;
  margin: 0 auto;
  justify-content: space-between;

  .footer-link {
    padding: 0.5rem 1rem;
    background: #d69d00;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: white;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
  }
}


.header-logo {
  height: 68px;
}
